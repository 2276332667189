body {
  height: auto;
  background-color: #fffffff7;
  font-family: 'DINPro-regular', sans-serif;
  overflow-x: hidden;
  margin: 0;
}

#root {
  height: auto;
}

.App {
  text-align: center;
}

html {
  scroll-behavior: smooth;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.mask {
  width: 100%;
  height: 100%;
  background: #121212;
  position: fixed;
  top: 0;
  z-index: 1001;
  transition: 1s;
  display: flex;
  justify-content: center;
  align-items: center;
}


.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #282c34;;
  opacity: 1;
  visibility: visible;
  transition: opacity 1s ease, visibility 1s ease;
  z-index: 100000;
  margin: 0;
}

.loading-screen img {
  position: fixed;
  top: 40%;
  left: 42%;
  animation-duration: 1.5s;
  animation-name: logo;
  
}

@keyframes logo {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loading-screen.hidden {
  opacity: 0;
  visibility: hidden;
}


.SearchPage {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  transform: translateX(100%);
  transition: 0.4s;
}

.SearchPage--active {
  transform: translateX(0);
}

.container-id {
  display: flex;
  width: 1100px;
  justify-content: space-between;
  margin: 0 auto 0;
  padding-bottom: 50px;
}

.container-left {
  width: 388px;
  padding: 10px;
  margin-top:25px;
  margin-left: 40px;
}

.page_char {
  margin-left: 50px;
  font-weight: 700;
}


.container-right {
  display: flex;
  flex-direction: column;
  width: 565px;
  padding: 10px;
  margin-top: 20px;
  margin-right: 40px;
  line-height: 20px;
}

.container-right span{
  margin: 0px 0px 20px 0px;
    display: flex;
    align-items: center;
    font-weight: 500;
   font-family: 'DINPro-regular', sans-serif;
    font-size: 15px;
}

.container-right span a {
  margin: 0px 10px;
  color: red;
  font-weight: 600;
 font-family: 'DINPro-regular', sans-serif;
  text-transform: uppercase;

}

.sale-dis {
  margin-top: 20px;
    margin-left: 50px;
    font-weight: 500;
    width: 300px;
    display: flex;
}


.sold-img {
    position: absolute;
    border: none;
    width: 150;
    left: 120px;
    top: 122px;
    z-index: 1000;
}


.li-styles {
  margin-left: 50px;
}

.container-right p {
  margin: 18px 0px;
}

.container-right img {
  width: 565px;
  height: 350px;
  object-fit: cover;
  border: 2px solid black;

}

.address {
  font-weight: 700;
 font-family: 'DINPro-regular', sans-serif;
}

.sale-product-cart__map iframe {
  border: 2px solid black;
  border-radius: 15px;
}

.right-text {
  line-height: 20px;
}

.numbers {
  display: flex;
  width: 560px;
  flex-direction: column;
  line-height: 30px;
  position: relative;
 
}

.numbers a{
 text-decoration: none;
}

h3 {
  margin: 0;
 
}

.footer {
  position: absolute;
  left: 0;
  bottom: -30px;
  width: 100%;
  height: 70px;
  background-color: #0090ff;
}

.wrapper {
  position: relative;
  min-height: 140%;
}

.footer div {
  display: flex;
  margin: 0 auto 0;
  justify-content: center;
}


.action-container {
  margin: 0 auto 0;
  display: flex;
  flex-direction: column;
  width: 1090px;
  position: relative;
  top: 50px;
}


.action-suptitle {
  line-height: 20px;
  font-size: 16px;
}

.action-co p{
  margin-top: 50px;
  color: blue;
}

.action-co li {
 line-height: 30px;
}

.action-co li::marker {
  color: blue;
}

.action-rent {
  display: flex;
  font-size: 14px;
  margin-top: 50px;
  font-weight: 700;
  flex-direction: column;
}

.action-rent span {
  display: flex;
  font-size: 16px;
  align-items: center;
}

.action-rent p{
  font-size: 16px;
  position: relative;
  left: 10px;
}

.action-address {
  margin-top: 50px;
  font-size: 16px;
  line-height: 20px;
}

.action-address span {
  top: 30px;
  position: relative;
}

.action-suptitle i {
  color: orangered;
  font-size: 18px;
}

.text {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 0;
}

pre {
    margin: 0;
    padding: 0;
    line-height: 20px;
   font-family: 'DINPro-regular', sans-serif;
    text-align: center;
}

.characterPRE {
  margin: 0;
  padding: 0;
  line-height: 35px;
 font-family: 'DINPro-regular', sans-serif;
  text-align: left;
  font-weight: 500;
}


.discount {
  border: 3px solid red;
  padding: 15px;
  width: 310px;
  height: 280px;
  left: 75px;
  position: absolute;
  
}

.discount span {
  color: red;
  font-weight: 700;
 font-family: 'DINPro-regular', sans-serif;
}

.sale {
  margin: 20px 0px;
  display: flex;
  align-items: center; 
  justify-content: center;
  text-transform: uppercase;
 font-family: 'DINPro-regular', sans-serif;
}

.discount-end {
  position: relative;
  top: 20px;
}

.lastprice {
  text-decoration: line-through;
  text-decoration-color: red;
  margin-top: 50px;
}

.newprice {
  display: flex;
  align-items: center;
  font-size: 22px;
  justify-content: center;
  margin: 15px 0px;
  font-weight: 500;
  font-family: 'DINPro-regular', sans-serif;
}

.date-discount {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 700;
 font-family: 'DINPro-regular', sans-serif;

}

.ucan {
  width: 201px;
    text-align: center;
   font-family: 'DINPro-regular', sans-serif;
    font-weight: 500;
    line-height: 15px;
    margin-top: -5px;
}


.lastprice p {
  text-decoration:line-through;
 font-family: 'DINPro-regular', sans-serif;
  font-weight: 700;
  text-decoration-color: red;
}


.lastprice span {
  margin: 0 20px;
  color: red;
  font-size: 20px;
}


.end-discount {
  width: 170px;
  text-align: center;
  line-height: 15px;
 font-family: 'DINPro-regular', sans-serif;
  font-weight: 700;
  font-size: 13px;
}


.contacts-link {
  display: flex;
  width: 90px;
  margin-top: 10px;
  justify-content: space-between;
  position: absolute;
  right: -5px;
  top: -5px;
}

.contacts-link img {
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 15px;
}



/* Add this CSS in your existing stylesheet or create a new stylesheet and import it */

/* Customize the arrow styles as per your design */
.slider-arrow {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: gray;
  opacity: 0.7;
  cursor: pointer;
  z-index: 1;
}

.prev-arrow {
  left: 10px;
}

.next-arrow {
  right: 10px;
}


.actual-photo{
  width: 150px;
  height: 100px;
  object-fit: cover;
}


.slider-buttons {
  display: flex;
  width: 588px;
  justify-content: space-between;
  position: relative;
  top: -200px;
  align-items: center;
  left: -10px;
}

.slider-buttons button {
  background: none;
  border: 2px solid black;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: white;
}

.slider-buttons button:hover {
  background-color: #2caee2;
  transition: all .3s ease;
}

.next img,
.prev img{
  width: 25px;
  height: auto;
  border: none;
  position: relative;
  cursor: pointer;
 
}

.prev {
  transform: rotate(90deg);
  top: 3px;
  left: 5px;
}

.next {
  top: -5px;
    right: -3px;
}

li::marker {
  color: #2caee2;
  
}

.downloadCP {
  background: none;
  background-color: none;
  border: none;
  text-decoration: underline;
  color: red;
  cursor: pointer;
}

.downloadCP:hover {
  color: #0090ff;
}

.admin-img-2 {
  position: fixed;
  top: 10px;
  left: 50px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.admin-img {
  position: fixed;
  top: 10px;
  right: 50px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.file-container {
  margin-top: 80px;
}

.file-now {
  margin-top: 10px;
}

.space-admin {
  display: block;
    content: "";
    width: 100%;
    height: 2px;
}

.h3__sale {
  margin: 0px;
  font-size: 15px;
  width: 440px;
  line-height: 20px;
}

.page_hr {
  margin: -10px 0px 20px 50px;
}


@media (max-width: 1100px) {
  .container-id {
    width: 990px;
    display: flex;
    flex-direction: column-reverse;

  }
}

@media (max-width: 990px) {
  .container-id {
    width: 400px;
  }
  .numbers {
    width: 460px;
  }

  .container-right img {
    width: 460px;
    height: 300px;

  }
  .slider-buttons {
    width: 485px;
  }

  .next img, .prev img {
    width: 25px;
    height: auto;
  }

  .contacts-link img {
    width: 50px;
    height: 50px;
  }

  .sale-product-cart__map {
    width: 460px;
  }

  .container-right {
    margin-top: 0;
  }
}

@media (max-width: 768px) {
  .container-id {
    width: auto;
    
  }

  .container-left {
    margin-left: -5px;
  }

  .container-right {
   width: auto;
  }

  .characterPRE {
    font-size: 13px;
    line-height: 30px;
  }
  .sale-dis {
    margin-left: 30px;
  }

  .container-right img {
    width: 530px;
    height: 355px;
  }

  .next img, .prev img {
    width: 25px;
    height: auto;
  }

  .contacts-link img {
    width: 50px;
    height: 50px;
  }

  .slider-buttons {
    width: 550px;
  }

  .sale-product-cart__map {
    width: 530px;
  }

  .numbers {
    width: 530px;
  }

  .container-right span {
    font-size: 13px;
  }

}


@media (max-width: 568px) {
  .container-right img {
    width: 320px;
    height: 255px;
  }
  .slider-buttons {
    width: 340px;
  }

  .loading-screen img {
    width: 50%;
    left: 25%;
  }

  .next img, .prev img {
    width: 20px;
    height: 20px;
  }
  .sale-product-cart__map {
    width: 330px;
  }

  .contacts-link img {
    width: 40px;
    height: 40px;
    margin-left: 10px;
    margin-top: 70px;
  }

  .page_char {
    margin-left: 30px;
    font-weight: 700;
  }

  .page_hr {
    margin: -10px 0px 20px 30px;
  }

  .li-styles {
    margin-left: 30px;
  }

  .numbers {
    width: 0;
  }
  .contacts-link {
    width: 50px;
    left: 10px;
  }

  .h3__sale {
    width: 300px;
    margin-left: 20px;
  }

  .discount {
 
    left: 25px;
    
  }
}