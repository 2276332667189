.header {
    background-color: #2caee2;
    position: fixed;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50px;
    border-bottom: 1px solid black;
    z-index: 100;
}

.container {
    width: 1100px;
    height: 50px;
    display: flex;
    justify-content: space-between;
}



.img {
    width: 130px;
    height: 130px;
    z-index: 3;
    position: fixed;
    margin-left: -5px;
}

.info {
    display: flex;
    align-items: center;
}

.info span{
    margin: 0 20px;
    text-transform: uppercase;
    font-family: "Montserrat", sans-serif;

}

.containerNav {
    position: fixed;
}

.NavLink {
    position: fixed;
    display: flex;
    width: 705px;
    margin-left: -10px;
    top: 65px;
    flex-direction: row;
    justify-content: flex-end;
    z-index: 51;
}


.NavLink::before {
    content: "";
    display: block;
    background-color: white;
    height: 49px;
    top: 51px;
    width: 100%;
    left: 0px;
    position: fixed;
    z-index: 1;
 
}

.NavLink::after {
    content: "";
    display: block;
    background-color: black;
    height: 1px;
    top: 100px;
    width: 100%;
    left: 0px;
    position: fixed;
}

.NavLink a {
    display: flex;
    margin: 0 20px;
    text-decoration: none;
    color: black;   
    z-index: 51;
    text-transform: uppercase;
    font-weight: 700;
}

.NavLink a:hover {
    color: #2caee2;
    transition: all .2s ease;

}



@media(max-width: 1100px) {
    .container {
        width: 990px;
    }
}

@media(max-width: 990px) {
    .container {
        width: 768px;
    }

    .NavLink a {
        margin: 0 5px 0 23px;

    }

    .NavLink a,
    .info span{ 
        font-size: 14px;
    }

    .info span{ 
        margin: 0 5px 0 20px;
    }

    .NavLink {
        margin-left: -135px;
    }
}

@media(max-width: 768px) {

    .img {
        left: 0;
    }

}


.nav {
    display: flex;
    padding: 0 10px;
    margin-top: 15px;
    justify-content: center;
    margin-left: 85px;
}

.li {
    list-style: none;
}

.item a{
    text-decoration: none;
    margin: 0 50px 30px 0;
    font-size: 22px;
    font-weight: 500;
    color: black;
    text-align: left;
    text-transform: uppercase;
    display: flex;
    font-family: 'Sofia Sans';
    width: 100%;
}

.navigation {
    display: flex;
    position: absolute;
   
}

.navigation li {
    list-style: none;
    margin: 100px 19px 0px 18px;
    position: relative;
    z-index: 3;
}

.navigation a {
   
    text-decoration: none;
    text-transform: uppercase;
    color: black;
    font-weight: 700;

}

.back {
    content: "";
    display: block;
    width: 100%;
    background-color: white;
    height: 50px;
    position: fixed;
    z-index: 1;
    top: 50px;
    opacity: 1;
}

.back:after {
    content: "";
    display: block;
    width: 100%;
    background-color: black;
    height: 2px;
    position: fixed;
    z-index: 1;
    top: 100px;
    opacity: 1;
}


.itemcontacts a{
  text-decoration: none;
    margin: 0 30px 0 0;
    font-size: 22px;
    color: black;
    font-family: 'Sofia Sans';
}

.itemcontacts a:hover{
    font-weight: 700;
    color: black;
  }

.item a:hover{
    font-weight: 700;
      color: black;
  }

.color {
  color: white;
}

.item img {
    margin-top: -15px;
}

.cloud {
    position: absolute;
    z-index: 10000;
    margin-left: 58px;
    margin-top: 10px;
    font-family: 'Sofia Sans';
    font-size: 26px;
    font-weight: 700;
}


.svg {
    width: 30px;
    height: 30px;
    
  
    
}

.svgPosition {
    margin-left: 3px;
}

.action {
  margin-left: 0px;

}


.menu {
  opacity:0;
  position: relative;
  top: -51px;
  left: -50px;
  font-size: 45px;
  text-decoration: none;
  border: 1px solid black;
}

.menus {
    opacity:1;
    position: relative;
    top: 0px;
    left: 2px;
    font-size: 15px;
    text-decoration: none;
    color: black;
}

.menus:after {
    content: "";
    width: 178px;
    left: -20px;
    opacity: 0;
    margin-top: -10px;
    border-radius: 10px;
    height: 45px;
    position: absolute;
    background-color: rgb(0, 0, 0, 0.08);
    transition: all .2s ease;
}

.menus:hover {
    color: black;
}

.menus:hover:after {
    opacity: 1;
}


.menuContent {
    background-color: white;
    width: 1100%;
    height: 370px;
    padding: 10px 19px;
    right: 323px;
    top: 29px;
    position: relative;
    z-index: 100;
    border: 2px solid black;
}

.menusContent {
    background-color: white;
    border-radius: 15px;
    padding: 10px 20px;
    margin-top: -10px;
    right: 0px;
    width: 190px;
    position: absolute;
    z-index: 10000;
    border: 1px solid black;
  }





.planet {
    display: flex;
    
}

.planet svg {
    width: 20px;
    margin-left: -10px;
    margin-right: 5px;
    margin-top: 3px;
}


.planetSecond {
    display: flex;
    
}

.planetSecond svg {
    width: 20px;
    margin-left: -10px;
    margin-right: 5px;
    margin-top: 3px;
}



.planetSecond:after {
    content: "";
    width: 178px;
    left: 0px;
    opacity: 0;
    margin-top: -10px;
    border-radius: 10px;
    height: 45px;
    z-index: 100;
    position: absolute;
    background-color: rgb(0, 0, 0, 0.08);
    transition: all .2s ease;
}

.planetSecond svg {
    width: 20px;
}


.planetSecond:hover:after{
  opacity: 1;
}

.planetSecond a{
    text-decoration: none;
    color: black;
}

.profile {
    position: relative;
    text-decoration: none;
    font-weight: 100;
    margin-top: 3px;
}



.profile svg{
    width: 20px;
    margin-left: -12px;
    margin-right: 5px;
    margin-top: -3px;
}

.profile a{
    text-decoration: none;
    color: black;
    font-weight: 0;
    font-size: 15px;
   
}

.profile a:after{
    content: "";
    width: 178px;
    left: -20px;
    opacity: 0;
    margin-top: -10px;
    border-radius: 10px;
    height: 45px;
    z-index: 100;
    position: absolute;
    background-color: rgb(0, 0, 0, 0.08);
    transition: all .2s ease;
   
}

.profile a:hover:after{
    opacity: 1;
   
}


.profilelink {
    font-weight: 100;
    margin-left: 5px;
}



.language {
    margin-left: -9px;

}

.language img {
    width: 20px;
    border-radius: 5px;
}

.hr {
    width: 150px;
    margin: 10px 0;
    margin-left: -7px;
    padding: 0;
}


.language:after {
    content: "";
    width: 186px;
    left: 0;
    opacity: 0;
    margin-top: -10px;
    border-radius: 10px;
    height: 45px;
    position: absolute;
    background-color: rgb(0, 0, 0, 0.08);
    transition: all .2s ease;
}

.language:hover:after {

    opacity: 1;

}
 

#dropdown-menu {
    display: none;
  }
  
  #dropdown-toggle:hover + #dropdown-menu {
    display: block;
  }


  .menuselect {
    display: block;
  }

  .burgerMenu {
    position: fixed;
    right: 0;
    width: 40px;
    margin-top: -22px;
  }

  .burgerButton {
    margin-top: 7px;
  }

  .spanBurger {
    content: "";
    display: block;
    margin-top: 6px;
    width: 25px;
    height: 3px;
    background-color: white;
    position: fixed;
  }

  .spanBurger:after {
    content: "";
    display: block;
    width: 25px;
    top: 25px;
    height: 3px;
    background-color: white;
    position: fixed;
  }

  .spanBurger:before {
    content: "";
    display: block;
    width: 25px;
    height: 3px;
    top: 32px;
    background-color: white;
    position: fixed;
  }


  @media (max-width: 1270px) {
    .item a{
        font-size: 19px;
    }
    .itemcontacts a{
        font-size: 19px;
        
    }
  }


@media (max-width: 1200px) {
    .nav {
        margin-left: 0px;
    }
    .itemcontacts a{
      font-size: 17px;
      display: none;
  }
   
}


@media (max-width: 990px) { 
.info span{
    display: none;
}

.navigation {
    display: none;
}

.back {
    display: none;
    opacity: 0;
}
.back:after {
    display: none;
    opacity: 0;
}



}

@media (max-width: 768px) {
  .item a{
    font-size: 17px;
    display: flex;
    padding: 0 0px;
    justify-content: center;
    text-align: left;
    width: 375px;
}


.menus {
  top: 0;
  left: 0;
  font-size: 14px;
}
    .menuselect {
        display: none;
      }
      .hr {
        width: 375px;
    margin: 10px 0;
    margin-left: -23px;
    padding: 0;
    }

    .itemcontacts a{
          font-size: 17px;
          display: none;
      }

      .svg {
        margin-left: -50px;
      }
      .svgContainer {
        display: flex;

      }

      .menu {
        opacity: 0;
    position: relative;
    top: -37px;
    left: -15px;
    width: 65px;
    display: block;
    height: 50px;
    font-size: 59px;
    text-decoration: none;
    border: 1px solid black;
      }

      .menuClose {
        display: flex;
        justify-content: flex-end;
        right: 10px;
        position: absolute;
        align-items: center;
        margin-top: 3px;
      }

      .menuClose p{
        font-size: 18px;
      }

      .menuClose svg{ 
        position: relative;
        width: 24px;
        right: 2px;
      }


    


  }
  

  @media(max-width: 540px) {
    .img {
        width: 100px;
        height: 100px;
        top: -7px;
    }
  }

  @media(max-width: 375px) {
    .item a {
        width: 320px;
    }
    .menuContent {
        right: 277px;
    }
  }

