.container {
    position: relative;
    top: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 1100px;
    margin: 0 auto 0;
    
}


.title {
    margin-top: 20px;
}

.ul {
    text-align: left;
    width: 1100px;
    margin-left: -30px;
    margin-top: 50px;
    font-weight: 700;
}

.ul li {
    font-weight: normal;
    margin-top: 10px;
    margin-left: 20px;
}

.container span {
     text-align: left;
     
}

.container span a{
    text-decoration: none;
}

@media(max-width: 1100px) {
    .container,
    .ul,
    .container span  {
        width: 990px;
    }
}


@media(max-width: 990px) {
    .container,
    .ul,
    .container span  {
        width: 768px;
    }
}

@media(max-width: 768px) {
    .container,
    .ul,
    .container span  {
        width: 568px;
    }
}

@media(max-width: 568px) {
    .container,
    .ul,
    .container span  {
        width: 368px;
    }
}