body {
    font-family: 'DINPro-regular', sans-serif;
  overflow-x: hidden;
}

.table-cards{
    display: flex;
    align-items: center;
}

.image-container {
    height: 200px;
    display: flex;

    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.image-now {
    display: flex;
    align-items: center;
    font-size: 18px;
    color: black;
    padding: 10px 0px;
}

.input-edit {
    height: 200px;
    width: 500px;
    border-radius: 10px;
    letter-spacing: 1px;
    line-height: 22px;
    font-size: 18px;
    padding: 10px;
    border: 1px solid black;
}

.leftEpisode-edit {
    position: fixed;
    right: 125px;
    bottom: 20px;
    border: 1px solid black;
    background-color: white;
    border-radius: 50%;
    width: 34px;
    height: 35px;
  }


